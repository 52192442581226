import * as style from "./maker_page_template.module.scss"
import * as React from "react"
import { graphql } from 'gatsby'
import Layout from './layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Gallery from '@browniebroke/gatsby-image-gallery'
import { RatingControl } from "react-rating-control";
import Helmet from 'react-helmet';

// Section for bottle gallery
function BottleGallery({makername, node, bottleImageFiles}) {
  const files  = bottleImageFiles.filter(f => f.relativeDirectory === node.slug.replace('/review', '')).map(o => o.childImageSharp) 
  let filesWithAlt = files.map((e) => {
    e.thumbAlt = makername + " " + node.frontmatter.title + " review";
    return e;
  });
  return (
    <td width='50%'  className={style.imageGallery}>
                        {/* The bottle images */}                 
                        <Gallery images={filesWithAlt} rowMargin='0'/>
                      </td>
  );
}

export default function PageTemplate({ data }) {
  const image = getImage(data.mdx.frontmatter.maker_image) // The cider maker image
  const maker = data.mdx.frontmatter.maker // the cider maker code
  const makername = data.mdx.frontmatter.title // the cider maker name
  const ciders = data.reviews.nodes.filter(node => node.frontmatter.maker === maker) // all the ciders for this maker

  const bottleImageFiles = data.images.nodes // All the bottle images for this maker
  var description = 'Reviews of ciders:';
  ciders.map((node) => (
    description = description + makername + " " + node.frontmatter.title + ","
  ))
  const title = 'Reviews of ciders made by ' + makername
  const ogimage = 'https://ciderwithevans.com' + data.mdx.frontmatter.maker_image.childImageSharp.gatsbyImageData.images.fallback.src

  //console.log(ciders)
  //console.log("Hello")
  //console.log(data.mdx.frontmatter)
  //console.log(data.mdx.frontmatter.maker_image.childImageSharp.gatsbyImageData.images)

  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <meta name="image" content={ogimage}/>
      </Helmet>
      {/* The Cider maker logo */}
      <div className={style.banner}>
        <a href={data.mdx.frontmatter.link} className={style.makerLogo} target="_blank"  rel="noreferrer">
          <GatsbyImage
            image={image}
            alt={data.mdx.frontmatter.maker_image_alt}
          />
        </a>
      </div>

      <h1 className={style.makerTitle} >{makername}</h1>

      {/* The intro to the cidermaker */}
      <div className={style.makerSection}>
      <MDXRenderer >
        {data.mdx.body}
      </MDXRenderer>
      </div>

      <div>
        {
          //loop through the cider reviews 
          <table className={style.reviewSection}>
            {
              ciders.map((node) => (
                [
                <tr id={node.frontmatter.title}>
                  <td width='50%'>
                    {/* The cider review */}
                    <table>
                      <tr>
                        <td colSpan='2'>
                          <h2 className={style.ciderTitle}> {node.frontmatter.title}</h2>
                        </td>
                      </tr>
                      <tr className={style.reviewRow}>
                        <td colSpan='2' >
                          <div className={style.ciderType}>{node.frontmatter.type}</div>
                        </td>
                      </tr>
                      <tr className={style.reviewRow}>
                        <td colSpan='2'>
                          <MDXRenderer>
                            {node.body}
                          </MDXRenderer>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {node.frontmatter.rating != null &&
                          <RatingControl 
                            value = {node.frontmatter.rating}
                            primaryColor='gray'
                            secondaryColor='red'
                            fontsize='40px'
                            enabled={false}/>
                          }
                          {/* used https://github.com/dzevakov/rating-control-react*/}

                        </td>
                        <td>
                          {node.frontmatter.strength != null &&
                          <div className={style.ciderStrength}>{node.frontmatter.strength}%</div>
                        }
                        </td>
                      </tr>
                    </table>
                  </td >

                  <BottleGallery makername={makername} node={node} bottleImageFiles = {bottleImageFiles} />
                
                </tr>
                ,
                <tr className={style.spacerRow}>
                  <td colSpan='2'>
                  <hr className={style.divider}></hr>
                  </td>

                </tr>
                ]
                
              ))
            }

          </table>
        }
      </div>
    </Layout>
  )
}

export const rootQuery = graphql`
  query ($id: String, $rootDir: String) 
  {
    mdx(id: {eq: $id}) 
    {
      frontmatter 
      {
        title
        link
        maker
        page_type
        maker_image
        {
          childImageSharp 
          {
           gatsbyImageData (height:100)
		      }
		    }
        maker_image_alt
      }
      body
    }
    reviews: allMdx (filter: {frontmatter: { page_type: {ne: "maker"}}} sort: {fields: frontmatter___review_order})
    {
      nodes 
      {
        frontmatter 
        {
          maker
          title
          rating
          strength
          type
          review_order
        }
        id
        slug
        body
      }
    }
    images: allFile(filter: {ext: {regex: "/(jpg)|(JPG)|(png)|(webp)/"}, relativeDirectory: {regex: $rootDir}}) {
      nodes {
        childImageSharp {
          thumb: gatsbyImageData(width: 200)
          full: gatsbyImageData(layout: FULL_WIDTH)
        }
        base
        relativeDirectory
        ext
        name
      }
    }
  }
  `

