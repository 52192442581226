// extracted by mini-css-extract-plugin
export var banner = "maker_page_template-module--banner--ddV4j";
export var ciderStrength = "maker_page_template-module--ciderStrength--52Lvy";
export var ciderTitle = "maker_page_template-module--ciderTitle--1CTi8";
export var ciderType = "maker_page_template-module--ciderType--KcRMU";
export var divider = "maker_page_template-module--divider--fbvRx";
export var imageGallery = "maker_page_template-module--imageGallery--PXPrY";
export var makerLogo = "maker_page_template-module--makerLogo--Jy86E";
export var makerSection = "maker_page_template-module--makerSection--HchK-";
export var makerTitle = "maker_page_template-module--makerTitle--nUihm";
export var reviewRow = "maker_page_template-module--reviewRow--0kdVW";
export var reviewSection = "maker_page_template-module--reviewSection--I4ifS";
export var spacerRow = "maker_page_template-module--spacerRow--nxOWY";